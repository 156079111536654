<template>
	<div>
		<div class="form-group">
			<p>Nyene ibitungwa</p>
			<div class="form-group">
				<label for="nom">Izina</label>
				<input v-model="nom" id="nom" placeholder="Izina" />
			</div>
			<div class="form-group">
				<label for="prenom">Amatazirano</label>
				<input v-model="prenom" id="prenom" placeholder="Amatazirano" />
			</div>
			<div class="form-group">
				<label for="id">Ikarata Karangamuntu</label>
				<input v-model="id" id="id" placeholder="0000/000.0000" />
			</div>
		</div>
		<div class="form-group">
			<p>Ibitungwa afise</p>
			<div class="mini-form">
				<div class="form-group">
					<label for="">Inka</label>
					<input
						v-model="inka"
						type="number"
						min="0"
						placeholder="0"
					/>
				</div>
				<div class="form-group">
					<label for="">Ingurube</label>
					<input
						v-model="ingurube"
						type="number"
						min="0"
						placeholder="0"
					/>
				</div>
				<div class="form-group">
					<label for="">Impene</label>
					<input
						v-model="impene"
						type="number"
						min="0"
						placeholder="0"
					/>
				</div>
				<div class="form-group">
					<label for="">Intama</label>
					<input
						v-model="intama"
						type="number"
						min="0"
						placeholder="0"
					/>
				</div>
				<div class="form-group">
					<label for="">Ubukwavu</label>
					<input
						v-model="ubukwavu"
						type="number"
						min="0"
						placeholder="0"
					/>
				</div>
				<div class="form-group">
					<label for="">Ipanya</label>
					<input
						v-model="ipanya"
						type="number"
						min="0"
						placeholder="0"
					/>
				</div>
			</div>
		</div>
		<div class="form-group">
			<p>Arafise Ibiguruka canke ibindi?</p>
			<div class="label-group">
				<label for="non">
					<input type="radio" value="non" id="non" v-model="choix" />
					Oyaa
				</label>
				<label for="oui">
					<input type="radio" value="oui" id="oui" v-model="choix" />
					Ego
				</label>
			</div>
			<div class="mini-form" v-if="choix == 'oui'">
				<div class="form-group">
					<label for="">Inkoko</label>
					<input
						v-model="inkoko"
						type="number"
						min="0"
						placeholder="0"
					/>
				</div>
				<div class="form-group">
					<label for="">Imbata</label>
					<input
						v-model="imbata"
						type="number"
						min="0"
						placeholder="0"
					/>
				</div>
				<div class="form-group">
					<label for="">Idendo</label>
					<input
						v-model="idendo"
						type="number"
						min="0"
						placeholder="0"
					/>
				</div>
				<div class="form-group">
					<label for="">Ibindi</label>
					<input v-model="ibindi" type="number" min="0" />
				</div>
			</div>
		</div>

		<ion-button expand="block" @click="addAnimal">Kwemeza</ion-button>
		<notification-page />
	</div>
</template>

<script>
import axios from "axios";
import notificationPage from "./notificationPage.vue";
export default {
	components: { notificationPage },
	data() {
		return {
			id: "",
			nom: "",
			prenom: "",
			inka: 0,
			ingurube: 0,
			impene: 0,
			intama: 0,
			ubukwavu: 0,
			ipanya: 0,
			choix: "non",
			inkoko: 0,
			imbata: 0,
			idendo: 0,
			ibindi: 0,
		};
	},
	methods: {
		addAnimal() {
			let data = new FormData();
			if (this.choix == "oui") {
				data.append("proprietaire.card_id", this.id);
				data.append("proprietaire.nom", this.nom);
				data.append("proprietaire.prenom", this.prenom);
				data.append("vache", this.inka);
				data.append("porc", this.ingurube);
				data.append("chevre", this.impene);
				data.append("mouton", this.intama);
				data.append("lapin", this.ubukwavu);
				data.append("panya", this.ipanya);
				data.append("poule", this.inkoko);
				data.append("canal", this.imbata);
				data.append("dinde", this.idendo);
				data.append("autre", this.ibindi);
				data.append("volaille", true);
			} else {
				data.append("proprietaire.card_id", this.id);
				data.append("proprietaire.nom", this.nom);
				data.append("proprietaire.prenom", this.prenom);
				data.append("vache", this.inka);
				data.append("porc", this.ingurube);
				data.append("chevre", this.impene);
				data.append("mouton", this.intama);
				data.append("lapin", this.ubukwavu);
				data.append("panya", this.ipanya);
				data.append("poule", this.inkoko);
				data.append("canal", this.imbata);
				data.append("dinde", this.idendo);
				data.append("autre", this.ibindi);
				data.append("volaille", false);
			}

			axios
				.post(
					`${this.url}animal/proprietaire_animal/`,
					data,
					this.headers
				)
				.then(() => {
					this.$store.state.notification = {
						type: "success",
						message: "Ivyo mwakoze vyakunze!",
					};
					this.id = "";
					this.nom = "";
					this.prenom = "";
					this.inka = 0;
					this.ingurube = 0;
					this.impene = 0;
					this.intama = 0;
					this.ubukwavu = 0;
					this.ipanya = 0;
					this.inkoko = 0;
					this.imbata = 0;
					this.idendo = 0;
					this.ibindi = 0;
					this.choix = "none";
				})
				.catch((err) => {
					if (
						err.response.Status == 401 ||
						err.response.Status == 403
					) {
						this.refreshToken(this.addAnimal);
					}
					this.$store.state.notification = {
						type: "danger",
						message: "Habaye ikibazo, subiramwo!",
					};
				});
		},
	},
};
</script>

<style scoped></style>
