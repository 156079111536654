import { createStore } from 'vuex'

const store = createStore({
  state: {
    user: null,
    // url: 'http://127.0.0.1:8000/api/',
    // url: 'http://192.168.43.51:8000/api/',
    url: 'https://ebitungwa.amidev.bi/api/',
    owner: {},
    groups: [],
    current_animal: {},
    current_proprio: {},
    notification: { type: "", message: "" },
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})

export default store