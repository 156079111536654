<template>
	<ion-page>
		<ion-header>
			<ion-toolbar color="primary">
				<ion-buttons slot="start">
					<ion-menu-button></ion-menu-button>
				</ion-buttons>
				<ion-title>E-Bitungwa</ion-title>
			</ion-toolbar>
		</ion-header>
		<ion-content>
			<div class="container">
				<h1 class="title">Iyandikwa ry'ibitungwa vyabazwe</h1>
				<div class="form">
					<div class="content">
						<div class="form-group">
							<label for="type">Nyene ibitungwa</label>
							<input
								v-model="proprio"
								id="type"
								placeholder="Rondera Ukoresheje Inomero ya Karangamuntu"
								type="search"
								@keypress.enter="getProprio"
							/>
							<span v-if="check">{{ nomProprio }}</span>
						</div>
						<div class="form-group">
							<p>Ibitungwa Vyabazwe</p>
							<div class="mini-form">
								<div
									class="form-group"
									v-if="animal.vache !== 0"
								>
									<label for=""
										>Inka
										<span v-if="animal.vache !== 0">{{
											animal.vache
										}}</span></label
									>
									<input
										v-model="inka"
										type="number"
										min="0"
										:max="animal.vache"
										:placeholder="animal.vache"
									/>
								</div>
								<div
									class="form-group"
									v-if="animal.porc !== 0"
								>
									<label for=""
										>Ingurube
										<span v-if="animal.porc !== 0">{{
											animal.porc
										}}</span></label
									>
									<input
										v-model="ingurube"
										type="number"
										min="0"
										:max="animal.porc"
										:placeholder="animal.porc"
									/>
								</div>
								<div
									class="form-group"
									v-if="animal.chevre !== 0"
								>
									<label for=""
										>Impene
										<span v-if="animal.chevre !== 0">{{
											animal.chevre
										}}</span></label
									>
									<input
										v-model="impene"
										type="number"
										min="0"
										:max="animal.chevre"
										:placeholder="animal.chevre"
									/>
								</div>
								<div
									class="form-group"
									v-if="animal.mouton !== 0"
								>
									<label for=""
										>Intama
										<span v-if="animal.mouton !== 0">{{
											animal.mouton
										}}</span></label
									>
									<input
										v-model="intama"
										type="number"
										min="0"
										:max="animal.mouton"
										:placeholder="animal.mouton"
									/>
								</div>
								<div
									class="form-group"
									v-if="animal.lapin !== 0"
								>
									<label for=""
										>Ubukwavu
										<span v-if="animal.lapin !== 0">{{
											animal.lapin
										}}</span></label
									>
									<input
										v-model="ubukwavu"
										type="number"
										min="0"
										:max="animal.lapin"
										:placeholder="animal.lapin"
									/>
								</div>
								<div
									class="form-group"
									v-if="animal.panya !== 0"
								>
									<label for=""
										>Ipanya
										<span v-if="animal.panya !== 0">{{
											animal.panya
										}}</span></label
									>
									<input
										v-model="ipanya"
										type="number"
										min="0"
										:max="animal.panya"
										:placeholder="animal.panya"
									/>
								</div>
							</div>
						</div>
						<div class="form-group">
							<div class="mini-form">
								<div
									class="form-group"
									v-if="animal.poule !== 0"
								>
									<label for=""
										>Inkoko
										<span v-if="animal.poule !== 0">{{
											animal.poule
										}}</span></label
									>
									<input
										v-model="inkoko"
										type="number"
										min="0"
										:max="animal.poule"
										:placeholder="animal.poule"
									/>
								</div>
								<div
									class="form-group"
									v-if="animal.canal !== 0"
								>
									<label for=""
										>Imbata
										<span v-if="animal.canal !== 0">{{
											animal.canal
										}}</span></label
									>
									<input
										v-model="imbata"
										type="number"
										min="0"
										:max="animal.canal"
										:placeholder="animal.canal"
									/>
								</div>
								<div
									class="form-group"
									v-if="animal.dinde !== 0"
								>
									<label for=""
										>Idendo
										<span v-if="animal.dinde !== 0">{{
											animal.dinde
										}}</span></label
									>
									<input
										v-model="idendo"
										type="number"
										min="0"
										:max="animal.dinde"
										:placeholder="animal.dinde"
									/>
								</div>
								<div
									class="form-group"
									v-if="animal.autre !== 0"
								>
									<label for=""
										>Ibindi
										<span v-if="animal.autre !== 0">{{
											animal.autre
										}}</span></label
									>
									<input
										v-model="ibindi"
										type="number"
										min="0"
										:max="animal.autre"
										:placeholder="animal.autre"
									/>
								</div>
							</div>
						</div>
						<div class="form-group">
							<label for="">Uko vyagenze</label>
							<textarea v-model="comment" rows="4"></textarea>
						</div>
						<ion-button expand="block" @click="addDeath"
							>Kwemeza</ion-button
						>
					</div>
				</div>
			</div>
			<notification-page />
		</ion-content>
	</ion-page>
</template>

<script>
import axios from "axios";
import notificationPage from "./notificationPage.vue";
export default {
	components: { notificationPage },
	data() {
		return {
			proprio: "",
			result: {},
			animal: {},
			check: false,
			comment: "",
			inka: 0,
			ingurube: 0,
			impene: 0,
			intama: 0,
			ubukwavu: 0,
			ipanya: 0,
			inkoko: 0,
			imbata: 0,
			idendo: 0,
			ibindi: 0,
			nomProprio: "",
		};
	},
	methods: {
		getProprio() {
			axios
				.get(
					`${this.url}animal/?proprietaire__card_id=${this.proprio}`,
					this.headers
				)
				.then((res) => {
					this.result = res.data.results[0];
					this.animal = this.result;
					this.check = true;
					this.nomProprio =
						this.result.proprietaire.nom +
						" " +
						this.result.proprietaire.prenom;
				})
				.catch((err) => {
					if (
						err.response.status == 401 ||
						err.response.status == 403
					) {
						this.refreshToken(this.getProprio);
					}
				});
		},
		addDeath() {
			let data = new FormData();
			data.append("proprietaire", this.result.proprietaire.id);
			data.append("vache", this.inka);
			data.append("porc", this.ingurube);
			data.append("chevre", this.impene);
			data.append("mouton", this.intama);
			data.append("lapin", this.ubukwavu);
			data.append("panya", this.ipanya);
			data.append("poule", this.inkoko);
			data.append("canal", this.imbata);
			data.append("dinde", this.idendo);
			data.append("autre", this.ibindi);
			data.append("commentaire", this.comment);

			axios
				.post(
					`${this.$store.state.url}animalAbattu/`,
					data,
					this.headers
				)
				.then(() => {
					this.$store.state.notification = {
						type: "success",
						message: "Ivyo mwakoze vyakunze!",
					};
					this.inka = 0;
					this.ingurube = 0;
					this.impene = 0;
					this.intama = 0;
					this.ubukwavu = 0;
					this.ipanya = 0;
					this.inkoko = 0;
					this.imbata = 0;
					this.idendo = 0;
					this.ibindi = 0;
					this.comment = "";
					this.proprio = "";
					this.result = {};
				})
				.catch((err) => {
					if (
						err.response.Status == 401 ||
						err.response.Status == 403
					) {
						this.refreshToken(this.addDeath);
					}
					this.$store.state.notification = {
						type: "danger",
						message: "Habaye ikibazo, subiramwo!",
					};
				});
		},
	},
};
</script>

<style scoped></style>
