<template>
	<div>
		<div class="form-group">
			<label for="type">Nyene igitungwa</label>
			<input
				v-model="owner"
				id="type"
				placeholder="Rondera Ukoresheje Inomero ya Karangamuntu"
				type="search"
				@keypress.enter="getOwner"
			/>
			<span v-if="check">{{ nomProprio }}</span>
		</div>

		<div class="form-group">
			<p>Ibitungwa afise</p>
			<div class="mini-form">
				<div class="form-group">
					<label for="">Inka</label>
					<input
						v-model="inka"
						type="number"
						min="0"
						placeholder="0"
					/>
				</div>
				<div class="form-group">
					<label for="">Ingurube</label>
					<input
						v-model="ingurube"
						type="number"
						min="0"
						placeholder="0"
					/>
				</div>
				<div class="form-group">
					<label for="">Impene</label>
					<input
						v-model="impene"
						type="number"
						min="0"
						placeholder="0"
					/>
				</div>
				<div class="form-group">
					<label for="">Intama</label>
					<input
						v-model="intama"
						type="number"
						min="0"
						placeholder="0"
					/>
				</div>
				<div class="form-group">
					<label for="">Ubukwavu</label>
					<input
						v-model="ubukwavu"
						type="number"
						min="0"
						placeholder="0"
					/>
				</div>
				<div class="form-group">
					<label for="">Ipanya</label>
					<input
						v-model="ipanya"
						type="number"
						min="0"
						placeholder="0"
					/>
				</div>
			</div>
		</div>
		<div class="form-group">
			<p>Arafise Ibiguruka canke ibindi?</p>
			<div class="label-group">
				<label for="non">
					<input type="radio" value="non" id="non" v-model="choix" />
					Oyaa
				</label>
				<label for="oui">
					<input type="radio" value="oui" id="oui" v-model="choix" />
					Ego
				</label>
			</div>
			<div class="mini-form" v-if="choix == 'oui'">
				<div class="form-group">
					<label for="">Inkoko</label>
					<input
						v-model="inkoko"
						type="number"
						min="0"
						placeholder="0"
					/>
				</div>
				<div class="form-group">
					<label for="">Imbata</label>
					<input
						v-model="imbata"
						type="number"
						min="0"
						placeholder="0"
					/>
				</div>
				<div class="form-group">
					<label for="">Idendo</label>
					<input
						v-model="idendo"
						type="number"
						min="0"
						placeholder="0"
					/>
				</div>
				<div class="form-group">
					<label for="">Ibindi</label>
					<input v-model="ibindi" type="number" min="0" />
				</div>
			</div>
		</div>

		<ion-button expand="block" @click="addAnimal">Kwemeza</ion-button>
		<notification-page />
	</div>
</template>

<script>
import axios from "axios";
import notificationPage from "./notificationPage.vue";
export default {
	components: { notificationPage },
	data() {
		return {
			check: false,
			result: {},
			id: "",
			nom: "",
			prenom: "",
			inka: 0,
			ingurube: 0,
			impene: 0,
			intama: 0,
			ubukwavu: 0,
			ipanya: 0,
			choix: "non",
			inkoko: 0,
			imbata: 0,
			idendo: 0,
			ibindi: 0,
			owner: "",
			nomProprio: "",
		};
	},
	methods: {
		getOwner() {
			let user = this.$store.state.user;
			axios
				.get(
					`${this.url}animal/?proprietaire__card_id=${this.owner}&province__nom=${user.province}&commune__nom=${user.commune}&zone__nom=${user.zone}&colline__nom=${user.colline}`,
					this.headers
				)
				.then((res) => {
					this.result = {};
					this.result = res.data.results[0];
					this.check = true;
					this.nomProprio =
						this.result.proprietaire.nom +
						" " +
						this.result.proprietaire.prenom;
				})
				.catch((err) => {
					if (
						err.Response.status == 401 ||
						err.Response.status == 403
					) {
						this.refreshToken(this.getOwner);
					}
				});
		},
		addAnimal() {
			let data = new FormData();

			if (this.choix == "oui") {
				data.append("proprietaire", this.result.proprietaire.id);
				data.append("vache", this.inka);
				data.append("porc", this.ingurube);
				data.append("chevre", this.impene);
				data.append("mouton", this.intama);
				data.append("lapin", this.ubukwavu);
				data.append("panya", this.ipanya);
				data.append("volaille", true);
				data.append("poule", this.inkoko);
				data.append("canal", this.imbata);
				data.append("dinde", this.idendo);
				data.append("autre", this.ibindi);
			} else {
				data.append("proprietaire", this.result.proprietaire.id);
				data.append("vache", this.inka);
				data.append("porc", this.ingurube);
				data.append("chevre", this.impene);
				data.append("mouton", this.intama);
				data.append("lapin", this.ubukwavu);
				data.append("panya", this.ipanya);
				data.append("volaille", false);
				data.append("poule", this.inkoko);
				data.append("canal", this.imbata);
				data.append("dinde", this.idendo);
				data.append("autre", this.ibindi);
			}

			axios
				.post(`${this.url}animal/`, data, this.headers)
				.then(() => {
					this.$store.state.notification = {
						type: "success",
						message: "Ivyo mwakoze vyakunze!",
					};
					this.inka = 0;
					this.ingurube = 0;
					this.impene = 0;
					this.intama = 0;
					this.ubukwavu = 0;
					this.ipanya = 0;
					this.inkoko = 0;
					this.imbata = 0;
					this.idendo = 0;
					this.ibindi = 0;
					this.owner = "";
					this.choix = "non";
					this.result = {};
					this.nomProprio = "";
				})
				.catch((err) => {
					if (
						err.response.Status == 401 ||
						err.response.Status == 403
					) {
						this.refreshToken(this.addAnimal);
					}
					this.$store.state.notification = {
						type: "danger",
						message: "Habaye ikibazo, subiramwo!",
					};
				});
		},
	},
};
</script>

<style scoped>
.mini-form {
	display: grid;
	gap: 10px;
	grid-template-columns: repeat(auto-fit, minmax(7rem, 1fr));
}
.form-group {
	margin-bottom: 12px;
}
.form-group input {
	padding: 10px 12px;
}
.form-group label {
	font-size: 14px;
	color: #aaa;
}
.form-group .label-group,
.form-group .label-group label {
	display: flex;
	align-items: center;
	margin-right: 15px;
	color: #666;
}
input[type="radio"] {
	margin-right: 5px;
}
input:not([type="radio"]),
select {
	background: #fff;
	border-radius: 5px;
	color: #333;
	width: 100%;
	padding: 10px 0px 10px 16px;
	border: 2px solid var(--ion-color-primary);
}
.search-group {
	display: flex;
	align-items: center;
	overflow: hidden;
	background: #fff;
	border-radius: 5px;
	color: #333;
	border: 2px solid var(--ion-color-primary);
}
.search-group input {
	padding: 10px 0px 10px 16px;
	border: none;
	outline: none;
	color: #333;
	flex: 1 1;
}
.search-group .mdi {
	font-size: 24px;
	color: #fff;
	padding: 5px 10px;
	background: #dfdfdf;
	cursor: pointer;
}
</style>
