<template>
	<ion-page>
		<ion-header>
			<ion-toolbar color="primary">
				<ion-buttons slot="start">
					<ion-menu-button></ion-menu-button>
				</ion-buttons>
				<ion-title>Ibiharuro</ion-title>
			</ion-toolbar>
		</ion-header>
		<ion-content>
			<div class="container">
				<div class="card-container">
					<statAnimal />
					<statDeath />
					<statNait />
					<statAbattu />
				</div>
			</div>
		</ion-content>
	</ion-page>
</template>

<script>
import statAnimal from "../components/statAnimal.vue";
import statDeath from "../components/statDeath.vue";
import statNait from "../components/statNait.vue";
import statAbattu from "../components/statAbattu.vue";

export default {
	data() {
		return {};
	},
	components: {
		statAnimal,
		statDeath,
		statNait,
		statAbattu
	},
	watch: {},
	methods: {},
	mounted() {},
	computed: {},
};
</script>

<style scoped>
.chart {
	background: #f5f5f5;
	width: 100%;
	max-height: 400px;
}
.card-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
	gap: 1.5rem;
}
ion-card {
	--background: #ddd;
}

@media screen and (min-width: 40em) {
	.card-container {
		grid-template-columns: repeat(auto-fit, minmax(35rem, 1fr));
	}
}
</style>
