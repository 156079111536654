import { createRouter, createWebHistory } from '@ionic/vue-router';
import HomePage from '../views/HomePage.vue'
import addAnimal from '../components/addAnimal.vue'
import Proprietaire from '../views/ProprietaireView.vue'
import StatisticView from '../views/StatisticView.vue'
import Ivyavutse from '../views/ivyavutseView.vue'
import Ivyapfuye from '../views/ivyapfuyeView.vue'
import Ivyabazwe from '../views/ivyabazweView.vue'

import AddDeath from '../components/addDeath.vue'
import AddAbattu from '../components/addAbattu.vue'
import AddNait from '../components/addNait.vue'

const routes = [
  // { path: '/', redirect: '/home' },
  { path: '/', name: 'Home', component: HomePage },
  { path: '/ajouter-animal', name: 'Ajouter-Animal', component: addAnimal },
  { path: '/proprietaire', name: 'proprietaire', component: Proprietaire },
  { path: '/statistic', name: 'statistics', component: StatisticView },
  { path: '/ivyavutse', name: 'ivyavutse', component: Ivyavutse },
  { path: '/ivyapfuye', name: 'ivyapfuye', component: Ivyapfuye },
  { path: '/ivyabazwe', name: 'ivyabazwe', component: Ivyabazwe },

  { path: '/add-nait', name: 'nait', component: AddNait },
  { path: '/add-abattu', name: 'abattu', component: AddAbattu },
  { path: '/add-death', name: 'death', component: AddDeath },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
