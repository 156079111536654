<template>
	<div class="login-container">
		<div class="wrapper">
			<div class="logo-img">
				<img src="/static/avatar2.png" alt="" />
			</div>
			<div class="login">
				<transition
					enter-active-class="animate__animated animate__slideInDown animate__faster"
					leave-active-class="animate__animated animate__slideOutUp animate__faster"
				>
					<div class="body danger" v-if="visible">
						<div class="content">
							<span class="msg"
								>Raba neza ko ivyo mwashizemwo ari vyo</span
							>
							<ion-icon
								:src="getIcon('closeOutline')"
								@click="visible = false"
							></ion-icon>
						</div>
					</div>
				</transition>
				<div class="form-group">
					<input
						type="text"
						v-model="username"
						autofocus="True"
						placeholder="Izina"
						@keypress.enter="login"
					/>
				</div>
				<div class="form-group">
					<input
						type="password"
						v-model="password"
						name=""
						id=""
						placeholder="Kode"
						@keypress.enter="login"
					/>
				</div>
				<ion-button
					expand="block"
					type="submit"
					class="btn btn-submit"
					@click="login"
				>
					Kwinjira
				</ion-button>
				<hr />
				<!-- <div class="mot">
					<a href="" class="mot">Mot de passe oublie?</a>
				</div> -->
			</div>
		</div>
	</div>
</template>

<script>
import axios from "axios";
export default {
	data() {
		return {
			username: "",
			password: "",
			visible: false,
		};
	},
	methods: {
		login() {
			let data = { username: this.username, password: this.password };
			axios
				.post(this.$store.state.url + `login/`, data)
				.then((res) => {
					this.$store.state.user = res.data;
				})
				.catch(() => {
					this.visible = true;
				});
		},
	},
	watch: {
		visible: {
			deep: true,
			handler(new_val) {
				if (new_val) {
					setTimeout(() => {
						this.visible = false;
					}, 3000);
				}
			},
		},
	},
};
</script>

<style scoped>
.login-container {
	display: flex;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	flex-wrap: wrap;
	/* background: radial-gradient(var(--bg-secondary), var(--bg-primary)); */
}
.logo-img {
	position: relative;
	display: flex;
	justify-content: center;
	margin-bottom: 30px;
}
.logo-img img {
	width: 120px;
	height: 120px;
	border-radius: 50%;
}

.body {
	margin-bottom: 10px;
	padding: 10px;
	border-radius: 5px;
	border: 2px solid transparent;
}
.body .content {
	display: flex;
}
.danger {
	color: #721c24;
	background-color: #f7ddda;
	border-color: #721c24;
}
ion-icon {
	font-size: 24px;
	font-weight: 600;
	cursor: pointer;
	display: inline-block;
	padding-left: 10px;
}

.login,
.register,
.profile {
	width: 350px;
	padding: 20px;
	border: 1px solid var(--hard-grey);
	border-radius: 5px;
}
.form-group {
	margin-bottom: 15px;
}
.form-group > * {
	display: block;
	padding: 10px;
	width: 100%;
	font-style: italic;
	outline: none;
}
.form-group input {
	background: var(--white);
	border-radius: 2px;
	border: 2px solid;
}
.btn-submit {
	transition: all 0.3s;
}
.btn-submit:active {
	transform: scale(1.1);
}
hr {
	border: none;
	height: 1px;
	background: #555555;
	margin: 20px 0;
}
.mot {
	text-align: center;
	color: var(--light-grey);
	transition: 0.3s all ease;
}
a.mot:hover {
	color: var(--hard-grey);
}

@media only screen and (max-width: 576px) {
	.login,
	.register {
		width: 300px;
	}
	nav {
		text-align: left;
	}
	.logo {
		display: none;
	}
}
</style>
