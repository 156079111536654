<template>
	<div class="dialog">
		<div class="dialog-body">
			<div class="wrap">
				<div class="content">
					<div class="d-head">
						<div class="close" @click="$emit('close')">
							<ion-icon
								:src="getIcon('closeOutline')"
								class="mdi"
							></ion-icon>
						</div>
					</div>
					<ion-list>
						<ion-item v-if="animal.vache != 0"
							>Inka: {{ animal.vache }}</ion-item
						>
						<ion-item v-if="animal.chevre != 0"
							>Impene: {{ animal.chevre }}</ion-item
						>
						<ion-item v-if="animal.mouton != 0"
							>Intama: {{ animal.mouton }}</ion-item
						>
						<ion-item v-if="animal.porc != 0"
							>Ingurube: {{ animal.porc }}</ion-item
						>
						<ion-item v-if="animal.lapin != 0"
							>Ubukwavu: {{ animal.lapin }}</ion-item
						>
						<ion-item v-if="animal.panya != 0"
							>Ipanya: {{ animal.panya }}</ion-item
						>
						<ion-item v-if="animal.poule != 0"
							>Inkoko: {{ animal.poule }}</ion-item
						>
						<ion-item v-if="animal.dinde != 0"
							>Idendo: {{ animal.dinde }}</ion-item
						>
						<ion-item v-if="animal.canal != 0"
							>Imbata: {{ animal.canal }}</ion-item
						>
						<ion-item v-if="animal.autre != 0"
							>Ibindi: {{ animal.autre }}</ion-item
						>
					</ion-list>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			animal: this.$store.state.current_animal,
		};
	},
};
</script>

<style scoped>
.dialog {
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(0, 0, 0, 0.6);
	z-index: 1000;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.dialog::after {
	content: "";
	background: rgba(0, 0, 0, 0.6);
	filter: blur(3px);
	overflow: hidden;
	z-index: -1;
}

.dialog-body {
	width: 75%;
	border-radius: 8px;
	background: var(--white);
	position: relative;
	overflow: hidden;
}
.dialog-body .d-head {
	background: var(--grey);
	padding: 20px 10px;
}
.dialog-body .close {
	position: absolute;
	top: 0px;
	right: 0px;
	height: 25px;
	width: 25px;
	color: var(--ion-color-danger);
	cursor: pointer;
}
.dialog-body .close .mdi {
	font-size: 2rem;
}
ion-list {
	padding: 2em;
	padding-left: 1em;
	overflow-y: auto;
}
</style>
