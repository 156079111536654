<template>
	<ion-page>
		<ion-header>
			<ion-toolbar color="primary">
				<ion-buttons slot="start">
					<ion-menu-button></ion-menu-button>
				</ion-buttons>
				<ion-title>E-Bitungwa</ion-title>
			</ion-toolbar>
		</ion-header>
		<ion-content>
			<div class="container">
				<div style="text-align: right">
					<ion-button
						routerLink="/ajouter-animal"
						shape="round"
						class="btn"
						slot="end"
						v-if="user_is('Agent')"
					>
						<ion-icon
							:src="getIcon('addOutline')"
							slot="start"
						></ion-icon>
						Kwandika</ion-button
					>
					<ion-button
						routerLink="/add-abattu"
						shape="round"
						class="btn"
						slot="end"
						v-if="user_is('Agent Abattoire')"
					>
						<ion-icon
							:src="getIcon('addOutline')"
							slot="start"
						></ion-icon>
						Kwandika</ion-button
					>
				</div>
				<div class="search">
					<h1 class="title" v-if="user_is('Admin')">
						Ibitungwa Vyose
					</h1>
					<h1 class="title" v-if="user_is('Agent Abattoire')">
						Ibitungwa Bimaze Kubagwa
					</h1>
					<h1 class="title" v-if="user_is('Agent')">
						Ibitungwa Vyose Biri k'umutumba
					</h1>
				</div>
				<div class="content">
					<div class="d-grid">
						<ion-card>
							<ion-card-header>
								<ion-card-title> Inka </ion-card-title>
								<ion-card-title>{{ Inka }}</ion-card-title>
							</ion-card-header>
						</ion-card>
						<ion-card>
							<ion-card-header>
								<ion-card-title> Impene </ion-card-title>
								<ion-card-title>{{ Impene }}</ion-card-title>
							</ion-card-header>
						</ion-card>
						<ion-card>
							<ion-card-header>
								<ion-card-title> Ingurube </ion-card-title>
								<ion-card-title>{{ Intama }}</ion-card-title>
							</ion-card-header>
						</ion-card>
						<ion-card>
							<ion-card-header>
								<ion-card-title> Intama </ion-card-title>
								<ion-card-title>{{ Ingurube }}</ion-card-title>
							</ion-card-header>
						</ion-card>
						<ion-card>
							<ion-card-header>
								<ion-card-title> Ubukwavu </ion-card-title>
								<ion-card-title>{{ Ubukwavu }}</ion-card-title>
							</ion-card-header>
						</ion-card>
						<ion-card>
							<ion-card-header>
								<ion-card-title> Ipanya </ion-card-title>
								<ion-card-title>{{ Ipanya }}</ion-card-title>
							</ion-card-header>
						</ion-card>
						<ion-card>
							<ion-card-header>
								<ion-card-title> Inkoko </ion-card-title>
								<ion-card-title>{{ Inkoko }}</ion-card-title>
							</ion-card-header>
						</ion-card>
						<ion-card>
							<ion-card-header>
								<ion-card-title> Imbata </ion-card-title>
								<ion-card-title>{{ Imbata }}</ion-card-title>
							</ion-card-header>
						</ion-card>
						<ion-card>
							<ion-card-header>
								<ion-card-title> Idendo </ion-card-title>
								<ion-card-title>{{ Idendo }}</ion-card-title>
							</ion-card-header>
						</ion-card>
						<ion-card>
							<ion-card-header>
								<ion-card-title> Ibindi </ion-card-title>
								<ion-card-title>{{ Ibindi }}</ion-card-title>
							</ion-card-header>
						</ion-card>
					</div>
				</div>
			</div>
		</ion-content>
	</ion-page>
</template>

<script>
import { IonContent, IonPage } from "@ionic/vue";
import axios from "axios";
import { defineComponent } from "vue";

export default defineComponent({
	name: "HomePage",
	components: {
		IonContent,
		IonPage,
	},
	data() {
		return {
			animals: [],
			Inka: 0,
			Impene: 0,
			Intama: 0,
			Ingurube: 0,
			Ubukwavu: 0,
			Ipanya: 0,
			Inkoko: 0,
			Imbata: 0,
			Idendo: 0,
			Ibindi: 0,
		};
	},
	methods: {
		getAnimal() {
			let path = `${this.url}animalTotal/`;

			if (this.user_is("Agent")) {
				path += `?colline__nom=${this.user.colline}&colline__zone__nom=${this.user.zone}&colline__zone__commune__nom=${this.user.commune}&colline__zone__commune__province__nom=${this.user.province}`;
			}
			if (this.user_is("Agent Abattoire")) {
				path = `${this.url}animalAbattu/?agent=${this.$store.state.user.agent}&abattoire=${this.$store.state.user.abattoire}`;
			}

			axios
				.get(path, this.headers)
				.then((res) => {
					this.animals = res.data.results;
					for (let i = 0; i < this.animals.length; i++) {
						this.Inka += this.animals[i].vache;
						this.Impene += this.animals[i].chevre;
						this.Intama += this.animals[i].mouton;
						this.Ingurube += this.animals[i].porc;
						this.Ubukwavu += this.animals[i].lapin;
						this.Ipanya += this.animals[i].panya;
						this.Inkoko += this.animals[i].poule;
						this.Imbata += this.animals[i].canal;
						this.Idendo += this.animals[i].dinde;
						this.Ibindi += this.animals[i].autre;
					}
				})
				.catch((err) => {
					if (
						err.response.status == 401 ||
						err.response.status == 403
					) {
						this.refreshToken(this.getAnimal);
					}
					console.log(err);
				});
		},
	},
	mounted() {
		this.getAnimal();
	},
});
</script>

<style scoped>
.btn {
	margin-bottom: 10px;
}

.content {
	padding: 15px 0;
}
.content .d-grid {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
	gap: 1rem;
}
@media screen and (min-width: 40em) {
	.content .d-grid {
		grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
	}
}
.content .ion-card {
	margin-inline: 0;
}
ion-card-header {
	display: flex;
	flex-direction: column;
	gap: 15px;
}
</style>
