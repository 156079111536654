<template>
	<ion-card class="figure">
		<ion-card-header>
			<select v-model="fProvince" @change="filterProvAnimal()">
				<option selected value="">Intara</option>
				<option
					v-for="item in province"
					:value="item.nom"
					:key="item.id"
				>
					{{ item.nom }}
				</option>
			</select>
			<select
				v-if="showcommune"
				v-model="fCommune"
				@change="filterComuAnimal()"
			>
				<option selected value="">Komine</option>
				<option
					v-for="item in provCommunes"
					:value="item.nom"
					:key="item.id"
				>
					{{ item.nom }}
				</option>
			</select>
			<select
				v-if="showcommune"
				v-model="fZone"
				@change="filterZonAnimal()"
			>
				<option selected value="">Zone</option>
				<option
					v-for="item in comZones"
					:value="item.nom"
					:key="item.id"
				>
					{{ item.nom }}
				</option>
			</select>
			<!-- 			<select
				v-if="showcommune"
				v-model="fColline"
			>
				<option selected value="">Umutumba</option>
				<option
					v-for="item in zoneColline"
					:value="item.nom"
					:key="item.id"
				>
					{{ item.nom }}
				</option>
			</select> -->
		</ion-card-header>
		<ion-card-content>
			<div class="chart">
				<BarChart :chart-data="forChart" />
			</div>
		</ion-card-content>
	</ion-card>
</template>

<script>
import axios from "axios";
import { BarChart } from "vue-chart-3";
import {
	Chart as Chartjs,
	Title,
	Tooltip,
	Legend,
	BarElement,
	CategoryScale,
	LinearScale,
	BarController,
} from "chart.js";
Chartjs.register(
	Title,
	Tooltip,
	Legend,
	BarElement,
	CategoryScale,
	LinearScale,
	BarController
);
export default {
	data() {
		return {
			animals: [],
			fetchedAnimal: [],
			groupType: {},
			label: [],
			db: [],
			province: [],
			communes: [],
			zones: [],
			collines: [],
			fProvince: "",
			fCommune: "",
			fZone: "",
			fColline: "",
			filteredAnimals: [],
			provCommunes: [],
			comZones: [],
			zoneColline: [],
			showcommune: false,
		};
	},
	components: {
		BarChart,
	},
	watch: {
		fetchedAnimal: {
			imediate: true,
			deep: true,
			handler(new_val) {
				this.animals = new_val;
			},
		},
		animals: {
			imediate: true,
			deep: true,
			handler(new_val) {
				//this.getdataLabel(new_val[0]);
				this.getAnimalsZone(new_val);
			},
		},
		filteredAnimals: {
			imediate: true,
			deep: true,
			handler(new_val) {
				this.animals = new_val;
			},
		},
	},
	methods: {
		getAnimals() {
			axios
				.get(this.url + `animalTotal/`, this.headers)
				.then((res) => {
					this.fetchedAnimal.push(...res.data.results);
					if (res.data.next) this.getAnimals();
				})
				.catch((err) => {
					if (
						err.response.Status == 401 ||
						err.response.Status == 403
					) {
						this.refreshToken(this.getAnimals);
					}
				});
		},
		getAnimalsZone(animal) {
			//console.log(animal)
			let item = {
				Inka: 0,
				Impene: 0,
				Intama: 0,
				Ingurube: 0,
				Ubukwavu: 0,
				Ipanya: 0,
				Inkoko: 0,
				Imbata: 0,
				Idendo: 0,
				Ibindi: 0,
			};

			animal.forEach((x) => {
				item.Inka += x.vache;
				item.Impene += x.chevre;
				item.Intama += x.mouton;
				item.Ingurube += x.porc;
				item.Ubukwavu += x.lapin;
				item.Ipanya += x.panya;
				item.Inkoko += x.poule;
				item.Imbata += x.canal;
				item.Idendo += x.dinde;
				item.Ibindi += x.autre;
			});

			this.getdataLabel(item);
		},
		getdataLabel(animal) {
			let a = animal;
			delete a.id;
			delete a.volaille;
			delete a.date;
			delete a.colline;
			this.label = Object.keys(a);
			this.db = Object.values(a);
		},
		getColline() {
			axios
				.get(this.url + "colline/", this.headers)
				.then((res) => {
					this.collines.push(...res.data.results);
					if (res.data.next) this.getColline();
				})
				.catch((err) => {
					if (
						err.response.status == 401 ||
						err.response.status == 403
					) {
						this.refreshToken(this.getColline);
					}
				});
		},
		filterProvAnimal() {
			if (this.fProvince == "") {
				this.showcommune = false;
				this.filteredAnimals = this.fetchedAnimal;
			} else {
				this.provCommunes = this.communes.filter((x) => {
					return x.province.nom == this.fProvince;
				});
				this.filteredAnimals = this.fetchedAnimal.filter((x) => {
					return (
						x.colline.zone.commune.province.nom == this.fProvince
					);
				});
				this.showcommune = true;
			}
		},
		filterComuAnimal() {
			console.log(this.fCommune);
			if (this.fCommune == "") {
				this.showcommune = false;
				this.filteredAnimals = this.fetchedAnimal;
				this.filteredAnimals = this.fetchedAnimal.filter((x) => {
					return (
						x.colline.zone.commune.province.nom == this.fProvince
					);
				});
			} else {
				this.filteredAnimals = this.fetchedAnimal
					.filter((x) => {
						return (
							x.colline.zone.commune.province.nom ==
							this.fProvince
						);
					})
					.filter((x) => {
						return x.colline.zone.commune.nom == this.fCommune;
					});
				this.comZones = this.zones.filter((x) => {
					return x.commune.nom == this.fCommune;
				});
			}
		},
		filterZonAnimal() {
			console.log(this.fZone);
			if (this.fZone == "") {
				this.filteredAnimals = this.fetchedAnimal;
				this.filteredAnimals = this.fetchedAnimal
					.filter((x) => {
						return (
							x.colline.zone.commune.province.nom ==
							this.fProvince
						);
					})
					.filter((x) => {
						return x.colline.zone.commune.nom == this.fCommune;
					});
			} else {
				this.filteredAnimals = this.fetchedAnimal
					.filter((x) => {
						return (
							x.colline.zone.commune.province.nom ==
							this.fProvince
						);
					})
					.filter((x) => {
						return x.colline.zone.commune.nom == this.fCommune;
					})
					.filter((x) => {
						return x.colline.zone.nom == this.fZone;
					});
			}
		},
		getProvince() {
			axios
				.get(this.url + "province/", this.headers)
				.then((res) => {
					this.province.push(...res.data.results);
					if (res.data.next) this.getProvince();
				})
				.catch((err) => {
					if (
						err.response.status == 401 ||
						err.response.status == 403
					) {
						this.refreshToken(this.getProvince);
					}
				});
		},
		getCommune() {
			axios
				.get(this.url + "commune/", this.headers)
				.then((res) => {
					this.communes.push(...res.data.results);
					if (res.data.next) this.getCommune();
				})
				.catch((err) => {
					if (
						err.response.status == 401 ||
						err.response.status == 403
					) {
						this.refreshToken(this.getCommune);
					}
				});
		},
		getZones() {
			axios
				.get(this.url + "zone/", this.headers)
				.then((res) => {
					this.zones.push(...res.data.results);
					if (res.data.next) this.getZones();
				})
				.catch((err) => {
					if (
						err.response.status == 401 ||
						err.response.status == 403
					) {
						this.refreshToken(this.getZones);
					}
				});
		},
	},
	mounted() {
		this.getProvince();
		this.getCommune();
		this.getZones();
		this.getAnimals();
		this.getColline();
	},
	computed: {
		contAnimals() {
			return Object.entries(this.groupType);
		},
		forChart() {
			return {
				labels: this.label,
				datasets: [
					{
						label: "Ibitungwa Vyose",
						data: this.db,
						backgroundColor: [
							"#859900",
							"#d33682",
							"#cb4b16",
							"red",
							"#42f54e",
							"#d742f5",
						],
					},
				],
			};
		},
	},
};
</script>

<style scoped></style>
