<template>
	<ion-app>
		<div v-if="!this.$store.state.user">
			<login-view />
		</div>
		<div v-else>
			<div>
				<ion-menu side="start" menu-id="first" content-id="main">
					<ion-content>
						<div class="user">
							<img src="/static/avatar2.png" alt="" />
							<h2>{{ user.first_name }} {{ user.last_name }}</h2>
						</div>
						<ion-list @click="closeMenu">
							<ion-item button routerLink="/">
								<ion-label>Ku ntango</ion-label>
							</ion-item>
							<div v-if="user_is('Agent')">
								<ion-item button routerLink="/proprietaire">
									<ion-label>Aborozi</ion-label>
								</ion-item>
								<ion-item button routerLink="/ivyavutse">
									<ion-label>Ivyavutse</ion-label>
								</ion-item>
								<ion-item button routerLink="/ivyapfuye">
									<ion-label>Ivyapfuye</ion-label>
								</ion-item>
							</div>
							<div v-if="user_is('Agent Abattoire')">
								<ion-item button routerLink="/ivyabazwe">
									<ion-label>Ivyabazwe</ion-label>
								</ion-item>
							</div>
							<ion-item
								button
								routerLink="/statistic"
								v-if="user_is('Admin')"
							>
								<ion-label>Ibiharuro</ion-label>
							</ion-item>
							<ion-item class="logout" @click="logout"
								>Kwugara
								<ion-icon
									:src="getIcon('logOutOutline')"
									slot="end"
								></ion-icon>
							</ion-item>
						</ion-list>
					</ion-content>
				</ion-menu>
			</div>
			<ion-router-outlet id="main" />
		</div>
	</ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet, menuController } from "@ionic/vue";
import { defineComponent } from "vue";
import LoginView from "./views/loginView.vue";

export default defineComponent({
	name: "App",
	components: {
		IonApp,
		IonRouterOutlet,
		LoginView,
	},
	methods: {
		logout() {
			localStorage.removeItem("user");
			this.$store.state.user = null;
		},
		closeMenu() {
			menuController.close();
		},
	},
	mounted() {
		var user = JSON.parse(localStorage.getItem("user"));
		if (user) {
			this.$store.state.user = user;
		}
	},
	watch: {
		"$store.state.user": {
			deep: true,
			handler(new_val) {
				if (new_val) {
					localStorage.setItem("user", JSON.stringify(new_val));
				} else {
					localStorage.removeItem("user");
				}
			},
		},
	},
});
</script>
<style src="./styles.css"></style>
