<template>
	<ion-page>
		<ion-header>
			<ion-toolbar color="primary">
				<ion-buttons slot="start">
					<ion-menu-button></ion-menu-button>
				</ion-buttons>
				<ion-title>Gushiramwo Igitungwa</ion-title>
			</ion-toolbar>
		</ion-header>
		<ion-content>
			<div class="container">
				<h2 class="title">Iyandikwa ry'ibitungwa vy'umworozi</h2>
				<div class="form">
					<div class="content">
						<div class="tabs">
							<span
								:class="{ active: choix }"
								@click="choix = true"
								>Mushasha</span
							>
							<span
								:class="{ active: !choix }"
								@click="choix = false"
								>Ahasanzwe</span
							>
						</div>
						<div class="wrap">
							<new-prop v-show="choix" />
							<existing-prop v-show="!choix" />
						</div>
					</div>
				</div>
			</div>
		</ion-content>
	</ion-page>
</template>

<script>
import newProp from "./newProp.vue";
import ExistingProp from "./existingProp.vue";
export default {
	components: { newProp, ExistingProp },
	data() {
		return {
			result: {},
			check: false,
			choix: true,
		};
	},
};
</script>

<style scoped>
.title {
	padding: 0px 20px;
}
.container {
	padding: 0px 0 20px;
	/* height: 100%; */
}
.content {
	padding: 0px 20px;
}
.wrap {
	padding: 20px 0;
}

.tabs {
	display: flex;
	border-bottom: 1px solid var(--ion-color-primary);
}
.tabs span {
	padding: 10px 2em;
	border-radius: 5px 5px 0 0;
	cursor: pointer;
}
.tabs span.active {
	background: var(--ion-color-primary);
	color: var(--ion-color-primary-contrast);
}
</style>
