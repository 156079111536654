import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import store from './store'
import axios from 'axios'
import '@mdi/font/css/materialdesignicons.min.css'

// import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';
import * as allIcons from 'ionicons/icons'
import {
  IonicVue, IonApp, IonContent, IonButton, IonCard, IonCardContent,
  IonButtons, IonCardHeader, IonCardTitle, IonCardSubtitle, IonItem,
  IonMenu, IonMenuButton, IonToolbar, IonHeader, IonLabel, IonIcon,
  IonTitle, IonList, IonPage, IonBackdrop
} from '@ionic/vue'

/* Theme variables */
import './theme/variables.css';

const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(store);

app.component('IonApp', IonApp)
app.component('IonContent', IonContent)
app.component('IonButton', IonButton)
app.component('IonCard', IonCard)
app.component('IonCardContent', IonCardContent)
app.component('IonButtons', IonButtons)
app.component('IonCardHeader', IonCardHeader)
app.component('IonCardTitle', IonCardTitle)
app.component('IonCardSubtitle', IonCardSubtitle)
app.component('IonItem', IonItem)
app.component('IonMenu', IonMenu)
app.component('IonMenuButton', IonMenuButton)
app.component('IonToolbar', IonToolbar)
app.component('IonHeader', IonHeader)
app.component('IonLabel', IonLabel)
app.component('IonIcon', IonIcon)
app.component('IonTitle', IonTitle)
app.component('IonList', IonList)
app.component('IonPage', IonPage)
app.component('IonBackdrop', IonBackdrop)

app.mixin({
  methods: {
    getIcon(name) {
      return allIcons[name]
    },
    user_is(name) {
      return this.$store.state.user.groups.includes(name)
    },
    loadGroups() {
      axios.get(this.url + `/groups/`, this.headers)
        .then((res) => {
          this.$store.state.groups = res.data
          console.log(this.$store.state.groups)
        }).catch((error) => {
          console.log(error)
        })
    },
    refreshToken(callback) {
      let refresh = this.$store.state.user.refresh
      if (!refresh) {
        this.$store.state.user = null;
      }
      axios.post(this.url + "refresh/", { "refresh": refresh })
        .then((response) => {
          this.$store.state.user.access = response.data.access
          callback()
        })
        .catch(() => {
          this.$store.state.user = null;
        })
    },
    formatDate(x) {
      if (!x) return '-'
      let date = new Date(x)
      return new Intl.DateTimeFormat(
        'fr-FR',
        { dateStyle: 'short' }
      ).format(date)
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    url() {
      return this.$store.state.url
    },
    headers() {
      return {
        headers: {
          "Authorization": "Bearer " + this.$store.state.user.access
        }
      }
    }
  }
})


app.mount('#app');

