<template>
	<transition
		enter-active-class="animate__animated animate__slideInDown animate__faster"
		leave-active-class="animate__animated animate__slideOutUp animate__faster"
	>
		<div
			class="body"
			:class="$store.state.notification.type"
			v-if="visible"
		>
			<div class="content">
				<div class="demo1">
					<div
						class="ui-success"
						v-if="$store.state.notification.type == 'success'"
					>
						<svg viewBox="0 0 100 100" class="animate">
							<filter id="dropshadow" height="100%">
								<feGaussianBlur
									in="SourceAlpha"
									stdDeviation="3"
									result="blur"
								/>
								<feFlood
									flood-color="rgba(76, 175, 80, 1)"
									flood-opacity="0.5"
									result="color"
								/>
								<feComposite
									in="color"
									in2="blur"
									operator="in"
									result="blur"
								/>
								<feMerge>
									<feMergeNode />
									<feMergeNode in="SourceGraphic" />
								</feMerge>
							</filter>

							<circle
								cx="50"
								cy="50"
								r="46.5"
								fill="none"
								stroke="rgba(45, 211, 111, 0.5)"
								stroke-width="5"
							/>

							<path
								d="M67,93 A46.5,46.5 0,1,0 7,32 L43,67 L88,19"
								fill="none"
								stroke="rgb(45, 211, 111)"
								stroke-width="5"
								stroke-linecap="round"
								stroke-dasharray="80 1000"
								stroke-dashoffset="-220"
								style="filter: url(#dropshadow)"
							/>
						</svg>
					</div>

					<div class="ui-error" v-else>
						<svg
							viewBox="0 0 87 87"
							version="1.1"
							xmlns="http://www.w3.org/2000/svg"
							xmlns:xlink="http://www.w3.org/1999/xlink"
						>
							<g
								id="Page-1"
								stroke="none"
								stroke-width="1"
								fill="none"
								fill-rule="evenodd"
							>
								<g
									id="Group-2"
									transform="translate(2.000000, 2.000000)"
								>
									<circle
										id="Oval-2"
										stroke="rgba(252, 191, 191, .5)"
										stroke-width="4"
										cx="41.5"
										cy="41.5"
										r="41.5"
									></circle>
									<circle
										class="ui-error-circle"
										stroke="#F74444"
										stroke-width="4"
										cx="41.5"
										cy="41.5"
										r="41.5"
									></circle>
									<path
										class="ui-error-line1"
										d="M22.244224,22 L60.4279902,60.1837662"
										id="Line"
										stroke="#F74444"
										stroke-width="3"
										stroke-linecap="square"
									></path>
									<path
										class="ui-error-line2"
										d="M60.755776,21 L23.244224,59.8443492"
										id="Line"
										stroke="#F74444"
										stroke-width="3"
										stroke-linecap="square"
									></path>
								</g>
							</g>
						</svg>
					</div>
				</div>

				<span
					class="msg success"
					v-if="$store.state.notification.type == 'success'"
					>{{ $store.state.notification.message }}</span
				>
				<span class="msg error" v-else>{{
					$store.state.notification.message
				}}</span>
			</div>
		</div>
	</transition>
</template>

<script>
export default {
	data() {
		return {
			visible: false,
		};
	},
	watch: {
		"$store.state.notification": {
			deep: true,
			handler(new_val) {
				if (new_val.message) {
					this.visible = true;
					setTimeout(() => {
						this.$store.state.notification = {
							type: "",
							message: "",
						};
					}, 3000);
				} else {
					this.visible = false;
				}
			},
		},
	},
	// methods: {
	// 	animate() {
	// 		document.querySelector("svg").classList.remove("animate");
	// 		setTimeout(function () {
	// 			document.querySelector("svg").classList.add("animate");
	// 		}, 10);
	// 	},
	// },
	// mounted() {
	// 	this.animate();
	// },
};
</script>

<style lang="less" scoped>
.body {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.7);
	z-index: 1001;
	display: flex;
	justify-content: center;
	align-items: center;
}
.content {
	background: var(--ion-color-primary-contrast);
	border-radius: 10px;
	padding: 2.5em 3em;
}
.msg {
	font-size: 18px;
}
.msg.success {
	color: var(--ion-color-success);
}
.msg.error {
	color: var(--ion-color-danger);
}

.demo1 {
	display: flex;
	justify-content: center;
	align-items: center;
}
.ui-success,
.ui-error {
	width: 100px;
	height: 100px;
	margin-bottom: 20px;
	// border:1px solid #eee;
}

.ui-error {
	&-circle {
		stroke-dasharray: 260.75219024795285px, 260.75219024795285px;
		stroke-dashoffset: 260.75219024795285px;
		animation: ani-error-circle 1.2s linear;
	}
	&-line1 {
		stroke-dasharray: 54px 55px;
		stroke-dashoffset: 55px;
		stroke-linecap: round;
		animation: ani-error-line 0.15s 1.2s linear both;
	}
	&-line2 {
		stroke-dasharray: 54px 55px;
		stroke-dashoffset: 55px;
		stroke-linecap: round;
		animation: ani-error-line 0.2s 0.9s linear both;
	}
}

@keyframes ani-error-line {
	to {
		stroke-dashoffset: 0;
	}
}

@keyframes ani-error-circle {
	0% {
		stroke-dasharray: 0, 260.75219024795285px;
		stroke-dashoffset: 0;
	}
	35% {
		stroke-dasharray: 120px, 120px;
		stroke-dashoffset: -120px;
	}
	70% {
		stroke-dasharray: 0, 260.75219024795285px;
		stroke-dashoffset: -260.75219024795285px;
	}
	100% {
		stroke-dasharray: 260.75219024795285px, 0;
		stroke-dashoffset: -260.75219024795285px;
	}
}

.ui-success svg {
	width: 100px;
	height: 100px;
}

.ui-success svg.animate path {
	animation: dash 0.75s linear both;
	animation-delay: 0.5s;
}

@keyframes dash {
	0% {
		stroke-dashoffset: 210;
	}
	75% {
		stroke-dashoffset: -220;
	}
	100% {
		stroke-dashoffset: -205;
	}
}
</style>
