<template>
	<ion-page>
		<ion-header>
			<ion-toolbar color="primary">
				<ion-buttons slot="start">
					<ion-menu-button></ion-menu-button>
				</ion-buttons>
				<ion-title>E-bitungwa</ion-title>
			</ion-toolbar>
		</ion-header>
		<ion-content>
			<div class="container">
				<div style="text-align: right">
					<ion-button
						routerLink="/add-death"
						shape="round"
						class="btn"
						slot="end"
					>
						<ion-icon
							:src="getIcon('addOutline')"
							slot="start"
						></ion-icon>
						Kwandika</ion-button
					>
				</div>
				<div class="search">
					<div class="form-group">
						<label for="search">Rondera</label>
						<div class="search-group">
							<input
								type="search"
								name=""
								autofocus="autofocus"
								v-model="keyword"
								id="search"
							/>
							<ion-icon
								:src="getIcon('searchOutline')"
								slot="start"
								class="mdi"
							></ion-icon>
						</div>
					</div>
				</div>
				<div class="title-filter">
					<h1 class="title">Urutonde rw'ibitungwa Vyapfuye</h1>
					<div class="date-filter leftie">
						<div class="leftie-form">
							<div>
								<label>Kuva :</label>
								<input type="date" v-model="du" />
							</div>
							<div>
								<label>Gushika :</label>
								<input type="date" v-model="au" />
							</div>
						</div>
						<div>
							<ion-button
								size="small"
								color="dark"
								@click="filterByDate()"
								>Filtre</ion-button
							>
						</div>
					</div>
				</div>
				<div v-for="date in renderedDates" :key="date">
					<div v-if="ibitungwaOn(date).length > 0">
						<div class="date-bar">
							<p class="date-bar-title">{{ formatDate(date) }}</p>
							<div class="bar"></div>
						</div>
						<ion-card
							v-for="item in ibitungwaOn(date)"
							:key="item.id"
						>
							<div @click="voirPlus(item)">
								<ion-card-header>
									<ion-card-subtitle
										>Itarike
										{{
											formatDate(item.date)
										}}</ion-card-subtitle
									><br />
									<ion-card-title
										>Ibitungwa vyapfuye:
										{{ item.count }}</ion-card-title
									>
									<br />
									<ion-card-subtitle>
										<p>
											Uwabizanye:
											{{ item.proprietaire.nom }}
											{{ item.proprietaire.prenom }}
										</p>
										<p>
											Karangamuntu:
											{{ item.proprietaire.card_id }}
										</p>
									</ion-card-subtitle>
								</ion-card-header>
								<!-- <ion-card-content>
							<p class="count">
								Igitigiri c'ivyapfuye:
								{{ countTotalAnimal(item) }}
							</p>
						</ion-card-content> -->
							</div>
						</ion-card>
					</div>
				</div>
			</div>
		</ion-content>
		<list-modal v-if="dialog" @close="close" />
	</ion-page>
</template>
<script>
import axios from "axios";
import listModal from "../components/listModal.vue";
export default {
	components: { listModal },
	data() {
		return {
			fetched_dead: [],
			count: 0,
			keyword: "",
			dialog: false,
			du: "",
			au: "",
		};
	},
	methods: {
		getIvyapfuye() {
			let user = this.$store.state.user;
			axios
				.get(
					`${this.url}dece/?cause=&commune__nom=${user.commune}&zone__nom=${user.zone}&colline__nom=${user.colline}`,
					this.headers
				)
				.then((res) => {
					this.fetched_dead = res.data.results.map((x) => {
						let new_x = x;
						new_x.count =
							x.vache +
							x.chevre +
							x.mouton +
							x.porc +
							x.lapin +
							x.panya +
							x.poule +
							x.canal +
							x.dinde +
							x.autre;
						return new_x;
					});
				})
				.catch((err) => {
					if (
						err.response.Status == 401 ||
						err.response.Status == 403
					) {
						this.refreshToken(this.getIvyapfuye);
					}
				});
		},
		filterByDate() {
			axios
				.get(
					this.url + `dece/?du=${this.du}&au=${this.au}`,
					this.headers
				)
				.then((res) => {
					this.fetched_dead = [];
					this.fetched_dead.push(...res.data.results);
					if (res.data.next) this.filterByDate();
				})
				.catch((error) => {
					if (
						error.response.status == 401 ||
						error.response.status == 403
					) {
						this.refreshToken(this.filterByDate);
					} else console.log(error);
				});
		},
		ibitungwaOn(date) {
			return this.filteredItems.filter((x) => x.date == date);
		},
		voirPlus(item) {
			this.$store.state.current_animal = item;
			this.dialog = true;
		},
		close() {
			this.dialog = false;
		},
	},
	mounted() {
		this.getIvyapfuye();
	},
	computed: {
		filteredItems() {
			return this.fetched_dead.filter((item) => {
				return (
					item.proprietaire.nom
						.toLowerCase()
						.indexOf(this.keyword.toLowerCase()) > -1 ||
					item.proprietaire.prenom
						.toLowerCase()
						.indexOf(this.keyword.toLowerCase()) > -1 ||
					item.proprietaire.card_id
						.toLowerCase()
						.indexOf(this.keyword.toLowerCase()) > -1
				);
			});
		},
		renderedDates() {
			const dates = [...new Set(this.fetched_dead.map((r) => r.date))];
			return dates.sort((a, b) => new Date(b) - new Date(a));
		},
	},
};
</script>

<style scoped>
ion-card {
	margin-bottom: 15px;
}
.count {
	text-decoration: underline;
}
</style>
