<template>
	<ion-page>
		<ion-header>
			<ion-toolbar color="primary">
				<ion-buttons slot="start">
					<ion-menu-button></ion-menu-button>
				</ion-buttons>
				<ion-title>Bene Ibitungwa</ion-title>
			</ion-toolbar>
		</ion-header>
		<ion-content>
			<div class="container">
				<div style="text-align: right">
					<ion-button
						routerLink="/ajouter-animal"
						shape="round"
						class="btn"
						slot="end"
					>
						<ion-icon
							:src="getIcon('addOutline')"
							slot="start"
						></ion-icon>
						Kwandika</ion-button
					>
				</div>
				<div class="search">
					<div class="form-group">
						<label for="search">Rondera</label>
						<div class="search-group">
							<input
								type="search"
								name=""
								autofocus="autofocus"
								v-model="keyword"
								id="search"
							/>
							<ion-icon
								:src="getIcon('searchOutline')"
								slot="start"
								class="mdi"
							></ion-icon>
						</div>
					</div>
				</div>
				<h1 class="title">Urutonde rw'aborozi</h1>
				<ion-card v-for="item in filteredItems" :key="item.id">
					<div @click="voirPlus(item)">
						<ion-card-header>
							<ion-card-title
								>{{ item.proprietaire.nom }}
								{{ item.proprietaire.prenom }}</ion-card-title
							>
							<ion-card-subtitle>
								{{ item.province.nom }} /
								{{ item.commune.nom }} / {{ item.zone.nom }} /
								{{ item.colline.nom }}
							</ion-card-subtitle>
						</ion-card-header>
						<ion-card-content>
							<p>Karangamuntu: {{ item.proprietaire.card_id }}</p>
							<p>Ibitungwa afise: {{ item.count }}</p>
						</ion-card-content>
					</div>
				</ion-card>
			</div>
		</ion-content>
		<list-modal v-if="dialog" @close="close" />
	</ion-page>
</template>
<script>
import axios from "axios";
import listModal from "../components/listModal.vue";
export default {
	components: { listModal },
	data() {
		return {
			fetched_owners: [],
			owner: [],
			groupProprio: {},
			count: 0,
			keyword: "",
			dialog: false,
		};
	},
	methods: {
		fechOwner() {
			let path = this.url + "animal/";
			if (this.user_is("Agent")) {
				path += `?province__nom=${this.user.province}&commune__nom=${this.user.commune}&zone__nom=${this.user.zone}&colline__nom=${this.user.colline}`;
			}
			axios
				.get(path, this.headers)
				.then((res) => {
					this.fetched_owners = res.data.results.map((x) => {
						let new_x = x;
						new_x.count =
							x.vache +
							x.chevre +
							x.mouton +
							x.porc +
							x.lapin +
							x.panya +
							x.poule +
							x.canal +
							x.dinde +
							x.autre;
						return new_x;
					});
				})
				.catch((err) => {
					if (
						err.response.status == 401 ||
						err.response.status == 403
					) {
						this.refreshToken(this.fechOwner);
					}
				});
		},
		voirPlus(item) {
			this.$store.state.current_animal = item;
			this.dialog = true;
		},
		close() {
			this.dialog = false;
		},
	},
	mounted() {
		this.fechOwner();
	},
	computed: {
		filteredItems() {
			return this.fetched_owners.filter((item) => {
				return (
					item.proprietaire.nom
						.toLowerCase()
						.indexOf(this.keyword.toLowerCase()) > -1 ||
					item.proprietaire.prenom
						.toLowerCase()
						.indexOf(this.keyword.toLowerCase()) > -1 ||
					item.proprietaire.card_id
						.toLowerCase()
						.indexOf(this.keyword.toLowerCase()) > -1
				);
			});
		},
	},
};
</script>

<style scoped>
ion-card {
	margin-bottom: 15px;
}
</style>
